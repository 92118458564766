"use client"

import { Children, Fragment, useEffect, useState, type ComponentProps, type PropsWithChildren } from "react"
import { clsx } from "clsx"
import { type EmblaCarouselType, type EmblaOptionsType } from "embla-carousel"
import Autoplay, { type AutoplayOptionsType } from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"
import { useIsomorphicLayoutEffect } from "usehooks-ts"

import { useOnResize, useRaf } from "@unlikelystudio/react-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"
import { AbstractLink, type AbstractLinkProps } from "~/components/abstracts/Link"
import { EmblaSliderControls } from "~/components/shared/EmblaSlider/Controls/index.client"
import { EmblaSliderStepper } from "~/components/shared/EmblaSlider/Stepper/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export type EmblaSliderProps = Partial<Pick<ComponentProps<typeof EmblaSliderControls>, "controlsStyle">> & {
  containerClassName?: string
  controlsClassName?: string
  controlButtonClassName?: string
  displayControlsOnHover?: boolean
  link?: Required<Pick<AbstractLinkProps, "href"> & { "aria-label": string }>
  options?: EmblaOptionsType
  hideOverflow?: boolean
  sliderClassName?: string
  withStepper?: boolean
  buttonStyle?: "bordered" | "cover"
  buttonType?: "arrow" | "chevron"
  onSlideChange?: (index: number) => void
  withBlurredPrev?: boolean
  withBlurredNext?: boolean
  withAutoplay?: boolean
  autoPlayOptions?: AutoplayOptionsType
}

const DEFAULT_AUTOPLAY_OPTIONS: AutoplayOptionsType = {
  playOnInit: true,
  delay: 3000,
}

/**
 * ! this component is not used at the moment
 * ! but could be in the future if we decide to switch all sliders
 */
export function EmblaSlider({
  className,
  children,
  containerClassName,
  controlsStyle,
  displayControlsOnHover,
  controlsClassName,
  controlButtonClassName,
  buttonStyle = "bordered",
  buttonType = "chevron",
  link,
  options,
  withAutoplay = false,
  autoPlayOptions,
  hideOverflow = false,
  sliderClassName,
  withStepper,
  onSlideChange,
  withBlurredPrev = false,
  withBlurredNext = false,
}: PropsWithClassName<PropsWithChildren<EmblaSliderProps>>) {
  const slidesCount = Children?.count?.(children)
  const [hasScrollLeft, setHasScrollLeft] = useState(false)
  const [hasScrollRight, setHasScrollRight] = useState(false)
  const t = useTranslate()
  const [sliderRef, emblaApi] = useEmblaCarousel({ ...options, active: slidesCount > 1, skipSnaps: true }, [
    WheelGesturesPlugin({ forceWheelAxis: "x" }),
    ...(withAutoplay ? [Autoplay({ ...DEFAULT_AUTOPLAY_OPTIONS, ...autoPlayOptions })] : []),
  ])

  function onSliderChange(slider: EmblaCarouselType) {
    const activeIndex = slider?.selectedScrollSnap?.()
    onSlideChange?.(activeIndex)
    updateScroll()
  }

  useEffect(() => {
    if (!emblaApi) return
    onSliderChange(emblaApi)
    emblaApi.on("reInit", onSliderChange).on("select", onSliderChange)
  }, [emblaApi, onSliderChange])

  const updateScroll = () => {
    setHasScrollLeft(emblaApi?.canScrollPrev() || false)
    setHasScrollRight(emblaApi?.canScrollNext() || false)
  }

  useIsomorphicLayoutEffect(() => {
    updateScroll()
  })

  useOnResize(() => {
    updateScroll()
  })

  useRaf(() => {
    updateScroll()
  })

  const asLink = Boolean(link?.href)
  const Tag = asLink ? AbstractLink : "div"

  const handleScrollIntoView = (direction: "left" | "right" = "right") => {
    if (direction === "right") {
      emblaApi?.scrollNext()
    }
    if (direction === "left") {
      emblaApi?.scrollPrev()
    }
  }

  return (
    <div className={clsx(className, css.slider)}>
      {withBlurredPrev && (
        <>
          <div className={clsx(css.gradientMask, css.gradientMaskLeft, { isVisible: hasScrollLeft })} />
          <button
            className={clsx(css.buttonLeft, { isVisible: hasScrollLeft })}
            aria-label={t("aria_arrow_prev")}
            type="button"
            onClick={() => handleScrollIntoView("left")}
          >
            <Icon name="ArrowSliderLeft" height={10} />
          </button>
        </>
      )}

      <div
        ref={sliderRef}
        className={clsx(containerClassName, css.container, hideOverflow ? css.hideOverflow : css.showOverflow)}
      >
        <Tag className={clsx(sliderClassName, css.slides)} {...(asLink && link)}>
          {children}
        </Tag>
      </div>
      {withBlurredNext && (
        <>
          <div className={clsx(css.gradientMask, css.gradientMaskRight, { isVisible: hasScrollRight })} />
          <button
            className={clsx(css.buttonRight, { isVisible: hasScrollRight })}
            aria-label={t("aria_arrow_next")}
            type="button"
            onClick={() => handleScrollIntoView("right")}
          >
            <Icon name="ArrowSliderRight" height={10} />
          </button>
        </>
      )}
      {slidesCount > 1 && (
        <Fragment>
          {withStepper && <EmblaSliderStepper emblaApi={emblaApi} length={Children?.count?.(children)} />}
          {controlsStyle && (
            <EmblaSliderControls
              controlClassName={controlButtonClassName}
              className={clsx(displayControlsOnHover && css.controlsOnHover, controlsClassName)}
              controlsStyle={controlsStyle}
              emblaApi={emblaApi}
              length={Children?.count?.(children)}
              buttonStyle={buttonStyle}
              buttonType={buttonType}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}
