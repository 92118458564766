"use client"

import { useCallback, useEffect, useRef, useState } from "react"
import { type EmblaCarouselType } from "embla-carousel"

import type { Nullish } from "~/@types/generics"
import { useAutoplay } from "~/components/shared/EmblaSlider/hooks/useAutoplay"
import { useAutoplayProgress } from "~/components/shared/EmblaSlider/hooks/useAutoplayProgress"

import * as css from "./styles.css"

export function EmblaSliderStepper({ emblaApi, length }: { emblaApi: Nullish<EmblaCarouselType>; length: number }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const { onAutoplayButtonClick } = useAutoplay(emblaApi)

  const updateStepper = useCallback((emblaApi: EmblaCarouselType) => {
    const newIndex = emblaApi?.selectedScrollSnap?.()
    setActiveIndex(newIndex)
  }, [])

  function handleClick(index: number) {
    setActiveIndex(index)
    if (emblaApi) emblaApi.scrollTo?.(index)
  }

  useEffect(() => {
    if (emblaApi && length > 1) emblaApi.on?.("select", updateStepper)
  }, [emblaApi, updateStepper, length])
  if (!length) return null

  return (
    // the data-comp attribute always us to apply custom without passing cascading classname prop
    <div data-comp="embla-stepper" className={css.EmblaSliderStepper}>
      {Array.from({ length })?.map((_, index) => {
        return (
          <EmblaSliderStepperItem
            key={`indicator-${index}`}
            emblaApi={emblaApi}
            index={index}
            onClick={() => onAutoplayButtonClick(() => handleClick(index))}
            activeIndex={activeIndex}
          />
        )
      })}
    </div>
  )
}

export function EmblaSliderStepperItem({
  index,
  activeIndex,
  emblaApi,
  onClick,
}: {
  index: number
  activeIndex: number
  emblaApi: Nullish<EmblaCarouselType>
  onClick: () => void
}) {
  const progressNode = useRef<HTMLDivElement>(null)
  const { showAutoplayProgress } = useAutoplayProgress(emblaApi, progressNode)
  return (
    <div className={css.stepper} onClick={onClick}>
      <div ref={progressNode} className={css.progressBar({ active: showAutoplayProgress && index === activeIndex })} />
    </div>
  )
}
