import { useCallback, useEffect } from "react"
import { type EmblaCarouselType } from "embla-carousel"
import { type AutoplayType } from "embla-carousel-autoplay"

import { type Nullish } from "~/@types/generics"

type UseAutoplayType = {
  toggleAutoplay: () => void
  onAutoplayButtonClick: (callback: () => void) => void
}

export const useAutoplay = (emblaApi: Nullish<EmblaCarouselType>): UseAutoplayType => {
  const onAutoplayButtonClick = useCallback(
    (callback: () => void) => {
      const autoplay = emblaApi?.plugins()?.autoplay as Nullish<AutoplayType>
      if (!autoplay) return

      const resetOrStop = autoplay.options.stopOnInteraction === false ? autoplay.reset : autoplay.stop

      resetOrStop()
      callback()
    },
    [emblaApi]
  )

  useEffect(() => {
    window.addEventListener("blur", play)
    window.addEventListener("focus", pause)

    return () => {
      window.removeEventListener("blur", play)
      window.removeEventListener("focus", pause)
    }
  }, [])

  const play = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay as Nullish<AutoplayType>
    if (!autoplay) return
    autoplay.play()
  }, [emblaApi])

  const pause = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay as Nullish<AutoplayType>
    if (!autoplay) return
    autoplay.stop()
  }, [emblaApi])

  const toggleAutoplay = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay as Nullish<AutoplayType>
    if (!autoplay) return

    const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play
    playOrStop()
  }, [emblaApi])

  return {
    toggleAutoplay,
    onAutoplayButtonClick,
  }
}
